.App {
  text-align: center;
}

.logo {
  width: 10vh;
  height:10vh;
  margin: 2vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    max-height: 3vh;
    animation: App-logo-spin infinite 20s linear;
  }
}

.animateTop{position:relative;animation:animatetop 0.6s}
@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}

h1, h2, h3, h4, h5, h6 {
    font-family: "Segoe UI",Arial,sans-serif;
    font-weight: 400;
    margin: 10px 0;
  }

h1 {
    font-size: 3em;
}

p {
  font-size: 1em;
}

.Header {
  background-color: rgb(0, 53, 88);
  min-height: 15vh;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Footer {
  background-color: rgb(0, 53, 88);
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.Body {
  background-color: rgb(0, 53, 88);
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Logo {
  display: inline-flex;
  height: 3vh;
  pointer-events: none;
}
